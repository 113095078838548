<template>
  <v-container fluid>
    <v-row no-gutters justify="center">
      <v-col cols="10" class="text-justify">
        <h1 class="text-center font-weight-bold mb-8" :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'">
          AVISO DE PRIVACIDAD INTEGRAL DEL INSTITUTO MEXICANO DE EJECUTIVOS DE
          FINANZAS, A.C. (IMEF)
        </h1>
        <p>
          Al interactuar con el Instituto Mexicano de Ejecutivos de Finanzas,
          A.C. (IMEF), usted acepta las disposiciones señaladas en este aviso de
          privacidad integral, el cual entra en vigor a partir del 01 de
          diciembre de 2023. Al participar de cualquier manera con el IMEF,
          manifiesta su expreso consentimiento de este aviso de privacidad
          integral.
        </p>
      </v-col>
      <v-col cols="10">
        <div
          v-for="({ title, content, type = 'PARAGRAPH' }, index) in rulesArray"
          :key="index"
        >
          <h4 class="text-uppercase title">{{ title }}:</h4>
          <div v-if="type == 'LIST'" class="mb-6">
            <ul v-for="(item, index) in content" :key="index">
              <li>
                {{ item }}
              </li>
            </ul>
          </div>
          <div v-else class="mb-6">
            <p>{{ content }}</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      rulesArray: [
        {
          title: "Identidad y domicilio de imef",
          content: `El responsable del tratamiento de sus datos personales es el Instituto Mexicano de Ejecutivos de
Finanzas, A.C. (IMEF), con domicilio en Av. Insurgentes Sur, No. 1431, Piso 10, Int. A, Col. Insurgentes
Mixcoac, Alcaldía Benito Juárez, CDMX, 03920. En lo sucesivo, se le podrá denominar indistintamente
como "IMEF" o "Instituto".`,
        },
        {
          title: "Finalidad del tratamiento de los datos",
          content: `Recopilamos diversas categorías de datos, tales como identificación, contacto, información laboral,
académica y de pagos. Cabe destacar que no se manejan datos personales sensibles, y tampoco
empleamos mecanismos automáticos para la recolección de datos al momento en que el titular
establece contacto con nosotros.
`,
        },
        {
          title:
            "Responsabilidad sobre datos personales publicados voluntariamente",
          content: `No asumimos responsabilidad por los datos personales publicados voluntariamente en nuestro sitio web
o redes sociales. Es importante señalar que no solicitamos ni monitoreamos dicha información, siendo
el usuario el único responsable de su publicación.`,
        },
        {
          title: "Principales usos de los datos",
          type: "LIST",
          content: [
            `Asociados del IMEF: Administración de la base de datos, registro de asistencias, envío de
invitaciones a eventos, gestión de cobranzas, contacto con comités y difusión de información.
`,
            `Candidatos a Membresía del IMEF: Evaluación y contacto para dar seguimiento a la solicitud.`,
            `Asistentes o Expositores en Eventos del IMEF: Registro de asistencia, envío de invitaciones,
gestión de cobranzas y difusión de información.`,
            `Empleados o Candidatos a Empleo en el IMEF: Evaluación y administración de la relación laboral.`,
            `Proveedores: Administración de la relación comercial o de servicios.`,
          ],
        },
        {
          title: "Derechos ARCO",
          content: `Usted tiene derecho al Acceso, Rectificación, Cancelación y Oposición (ARCO) respecto a sus datos
personales. Para ejercerlos, puede enviar su solicitud por escrito a la dirección de correo electrónico
stinajero@imef.org.mx o presentarla en nuestras oficinas`
        },
        {
          title: "Revocación del consentimiento",
          content: `Usted tiene derecho al Acceso, Rectificación, Cancelación y Oposición (ARCO) respecto a sus datos
personales. Para ejercerlos, puede enviar su solicitud por escrito a la dirección de correo electrónico
stinajero@imef.org.mx o presentarla en nuestras oficinas`
        },
        {
          title: "Seguridad de datos",
          content: `Hemos establecido rigurosas medidas de seguridad, tanto técnicas como físicas y administrativas, para
salvaguardar la integridad y confidencialidad de sus datos personales.`
        },
        {
          title: "Cambios al aviso de privacidad",
          content: `Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al
presente aviso de privacidad. Cualquier ajuste o modificación será comunicado por medio de nuestros
canales oficiales de comunicación y quedará debidamente reflejado con la fecha de última actualización
en nuestro sitio web.`
        },
        {
          title: "Contacto",
          content: `Para consultas sobre privacidad, puede ponerse en contacto con nosotros a través de
stinajero@imef.org.mx o 55 9151 5100.`
        },
      ],
    };
  },
};
</script>
